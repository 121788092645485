import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ProductDetail from "../../components/products/productDetail"
import ProductCatalog from "../../components/products/productCatalog"
import Toptitle from "../../components/toptitle"

const Louver = () => (
  <Layout>
    <SEO title="ルーバー | 製品紹介" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Louver</Toptitle>
        <div className="w-full pt-32 lg:mt-16 lg:pt-0">
          <div className="w-full md:flex">
            <StaticImage
              src="../../images/products/louver.jpg"
              alt="ルーバーの事例"
              className="w-full md:w-1/2"
            />
            <div className="w-full bg-secondery py-8 px-12 md:w-1/2">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                ルーバー
              </h2>
              <p className="font-gothic leading-loose">
                商業施設、マンションエントランスやオフィスなど幅広く納品実績があります。
                <br />
                アルミや木目調などの仕上げまでもご依頼可能です。
              </p>
            </div>
          </div>
        </div>
        <div className="mx-2 mt-32 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 lg:mx-0">
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/louver/louver1.png" />
            <ProductDetail name="LV-01" size="25×80" price="9,900" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/louver/louver2.png" />
            <ProductDetail name="LV-02" size="25×80" price="12,700" />
          </div>
        </div>
        <ProductCatalog />
      </section>
    </div>
  </Layout>
)

export default Louver
